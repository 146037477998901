import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { getStorage } from 'src/hooks/use-session-storage';

// ----------------------------------------------------------------------

// OVERVIEW
// const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/ballcharts/blank'));
// BALLCHARTS
const HomePage = lazy(() => import('src/pages/dashboard/ballcharts/home'));
const NewsPage = lazy(() => import('src/pages/dashboard/ballcharts/news'));
const NewPage = lazy(() => import('src/pages/dashboard/ballcharts/new'));
const DivisionsPage = lazy(() => import('src/pages/dashboard/ballcharts/divisions'));
const RostersPage = lazy(() => import('src/pages/dashboard/ballcharts/rosters'));
const PlayerPage = lazy(() => import('src/pages/dashboard/ballcharts/player'));
const SchedulesPage = lazy(() => import('src/pages/dashboard/ballcharts/schedules'));
const GamePage = lazy(() => import('src/pages/dashboard/ballcharts/game'));
const CalendarsPage = lazy(() => import('src/pages/dashboard/ballcharts/calendars'));
const StatsPage = lazy(() => import('src/pages/dashboard/ballcharts/stats'));
const StandingsPage = lazy(() => import('src/pages/dashboard/ballcharts/standings'));
const PhotosPage = lazy(() => import('src/pages/dashboard/ballcharts/photos'));
const VideosPage = lazy(() => import('src/pages/dashboard/ballcharts/videos'));
const LinksPage = lazy(() => import('src/pages/dashboard/ballcharts/links'));
const ContactsPage = lazy(() => import('src/pages/dashboard/ballcharts/contacts'));
const FieldsPage = lazy(() => import('src/pages/dashboard/ballcharts/fields'));
const HandoutsPage = lazy(() => import('src/pages/dashboard/ballcharts/handouts'));
const SponsorsPage = lazy(() => import('src/pages/dashboard/ballcharts/sponsors'));
const RegistrationsPage = lazy(() => import('src/pages/dashboard/ballcharts/registrations'));
const CustomsPage = lazy(() => import('src/pages/dashboard/ballcharts/customs'));
const ChatsPage = lazy(() => import('src/pages/dashboard/ballcharts/chats'));
const EmailsPage = lazy(() => import('src/pages/dashboard/ballcharts/emails'));
const EmailPage = lazy(() => import('src/pages/dashboard/ballcharts/email'));
const UpdatesPage = lazy(() => import('src/pages/dashboard/ballcharts/updates'));
const CommentsPage = lazy(() => import('src/pages/dashboard/ballcharts/comments'));
// BALLCHARTS ADMIN
const AdminMenuPage = lazy(() => import('src/pages/dashboard/ballcharts/admin/menu'));
const AdminMembersPage = lazy(() => import('src/pages/dashboard/ballcharts/admin/members'));
const AdminMemberPage = lazy(() => import('src/pages/dashboard/ballcharts/admin/member'));
// BALLCHARTS ACCOUNT
const AccountPage = lazy(() => import('src/pages/dashboard/ballcharts/account/member'));

// ----------------------------------------------------------------------
const teamObject = getStorage('team');

const teamHere = teamObject?.team || '';

// console.log('teamObject in dashboard', teamObject);

export const dashboardRoutes = [
  {
    path: `${teamHere}`,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <HomePage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
          { path: ':team/:id', element: <TourDetailsPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },

      // BALLCHARTS
      { path: 'home', element: <HomePage /> },
      {
        path: 'news',
        children: [
          { element: <NewsPage />, index: true },
          { path: ':id', element: <NewPage /> },
        ],
      },
      {
        path: 'rosters',
        children: [
          { element: <RostersPage />, index: true },
          { path: ':id', element: <PlayerPage /> },
        ],
      },
      {
        path: 'schedules',
        children: [
          { element: <SchedulesPage />, index: true },
          { path: ':id', element: <GamePage /> },
        ],
      },

      { path: 'divisions', element: <DivisionsPage /> },
      { path: 'calendars', element: <CalendarsPage /> },
      { path: 'stats', element: <StatsPage /> },
      { path: 'standings', element: <StandingsPage /> },
      { path: 'photos', element: <PhotosPage /> },
      { path: 'videos', element: <VideosPage /> },
      { path: 'links', element: <LinksPage /> },
      { path: 'contacts', element: <ContactsPage /> },
      { path: 'fields', element: <FieldsPage /> },
      { path: 'handouts', element: <HandoutsPage /> },
      { path: 'sponsors', element: <SponsorsPage /> },
      { path: 'registrations', element: <RegistrationsPage /> },
      {
        path: 'customs',
        children: [
          { path: ':id', element: <CustomsPage /> },
        ],
      },
      {
        path: 'chats',
        children: [
          { element: <ChatsPage />, index: true },
          { path: ':id', element: <ChatsPage /> },
        ],
      },
      {
        path: 'emails',
        children: [
          { element: <EmailsPage />, index: true },
          { path: ':id', element: <EmailPage /> },
        ],
      },
      { path: 'updates', element: <UpdatesPage /> },
      { path: 'comments', element: <CommentsPage /> },
      {
        path: 'member',
        children: [
          { path: 'account', element: <AccountPage /> },
        ],
      },

      {
        path: 'admin',
        children: [
          { path: 'menu', element: <AdminMenuPage /> },
          { path: 'members', element: <AdminMembersPage /> },
          { path: 'member',         
            children: [
              { path: 'new', element: <AdminMemberPage /> },
              { path: ':id', element: <AdminMemberPage /> },
            ],
          }
  
        ],
      },


    ],
  },
];
