import PropTypes from 'prop-types';
// @mui
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import { useAuthContext } from 'src/auth/hooks';
import { useTeam } from 'src/hooks/use-team';


// ----------------------------------------------------------------------


export default function ConfirmedGuard({ children }) {

  const { user } = useAuthContext();
  const team = useTeam();

  const confirmedAreas = ['chats', 'comments', 'emails', 'updates']; // WILL ALSO NEED TO GET THESE FROM TEAMS (sections_protected)
  const confirmedCustoms = [];
  if (team?.protected_pages){
    Object.entries(team?.protected_pages).forEach(([key, value]) => {
      // console.log(`${key} ${value}`);
      confirmedAreas.push(key);
      if (key === 'customs'){
        value.forEach((element) => {
          confirmedCustoms.push(element);
        });
      }
    });
  }

  // console.log('window.location.pathname', window.location.pathname);
  const pathArray = window.location.pathname.split('/');
  const page = pathArray[3];
  const pageid = pathArray[4];
  // console.log('page', page);
  let isMemberOnlyPage = false;
  if (page === 'customs'){
    isMemberOnlyPage = confirmedAreas.includes(page) && confirmedCustoms.includes(pageid);
  } else {
    isMemberOnlyPage = confirmedAreas.includes(page);
  }

  const isAdminOnlyPage = page === 'admin';

  const renderNotConfirmed = (
    <Container>
      <Stack
        sx={{
          m: 'auto',
          pb: 3,
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Alert severity="warning" variant="outlined" sx={{ width: 1, textAlign: 'center', justifyContent: 'center'}} >
          Your account has not yet been confirmed.  Once you are confirmed by the Admin, you will get full access.
        </Alert>
      </Stack>
    </Container>
  );  

  const renderNotHasTeam = (
    <Container>
      <Stack
        sx={{
          m: 'auto',
          pb: 3,
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Alert severity="warning" variant="outlined" sx={{ width: 1, textAlign: 'center', justifyContent: 'center'}} >
          You are not part of this team.  Click here if you would like to join this team.
        </Alert>
      </Stack>
    </Container>
  );    

  const renderNoAccess = (
    <Container>
      <Stack
        sx={{
          m: 'auto',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Alert severity="error" variant="filled" sx={{ width: 1, textAlign: 'center', justifyContent: 'center'}} >
          This area is for confirmed members only
        </Alert>
      </Stack>
    </Container>
  );    

  const renderAdminArea = (
    <Container>
      <Stack
        sx={{
          m: 'auto',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Alert severity="error" variant="filled" sx={{ width: 1, textAlign: 'center', justifyContent: 'center'}} >
          This area is for Admin members only
        </Alert>
      </Stack>
    </Container>
  );    

      if (isAdminOnlyPage && !team?.MemberAdmin){
        return renderAdminArea
      } 
      if (isMemberOnlyPage && !team?.MemberConfirmed){
        return renderNoAccess
      }
      // console.log('team?.MemberHasTeam', team?.MemberHasTeam);
      // console.log('team?.MemberConfirmed', team?.MemberConfirmed);
      return (
        <>
          {(user && team?.MemberHasTeam && !team?.MemberConfirmed) && renderNotConfirmed}
          {(false && user && !team?.MemberHasTeam) && renderNotHasTeam}
          {children}
        </>
      )
      
}
ConfirmedGuard.propTypes = {
  children: PropTypes.node,
};