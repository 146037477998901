import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
// import { useRouter } from 'src/routes/hooks';
//
import { useTeam } from 'src/hooks/use-team';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

const loginPaths = {
  ballcharts: paths.auth.ballcharts.loginteam,
  jwt: paths.auth.jwt.login,
  auth0: paths.auth.auth0.login,
  amplify: paths.auth.amplify.login,
  firebase: paths.auth.firebase.login,
};

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  // const router = useRouter();
  const team = useTeam();

  const { authenticated, method } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({
        team: team.team,
        returnTo: window.location.pathname.replace(process.env.PUBLIC_URL, ""), // remove the /t
      }).toString();


      const loginPath = loginPaths[method];  // ADD method to depency below if using this

      const href = `${loginPath}?${searchParams}`;

      console.log('href in auth-guard', href);

      // CHECKING FOR AUTHENTICATION HERE, may need to re-enable this
      // router.replace(href);
      setChecked(true);
    } else {
      setChecked(true);
    }
  // }, [authenticated, method, team, router]);
  }, [authenticated, method, team]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
